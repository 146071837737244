import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

import {
    CallbackFunction,
    CallbackParams,
    Callbacks,
    Event,
    Modal,
    ModalToken,
    ModalsMap,
    OpenedModal,
    Subscribers,
} from "./types";

const ALLOWED_EVENTS: Event[] = [
    "afterOpen",
    "beforeOpen",
    "afterClose",
    "beforeClose",
    "afterRegister",
];

export class ModalManager {
    _modalsMap: ModalsMap = new Map();
    _openedModals = new BehaviorSubject<OpenedModal<unknown>[]>([]);
    _subscribersMap: Subscribers = new Map();
    _callbacks: Callbacks = {
        afterOpen: [],
        beforeOpen: [],
        afterClose: [],
        beforeClose: [],
        afterRegister: [],
    };

    // Meta
    scroll_point = 0;

    /**
     * Register new modal
     */
    addModal<T>(
        modalToken: ModalToken<T>,
        defaultParams: Pick<T, keyof T> = {} as T
    ): void {
        if (this._modalsMap.has(modalToken)) return;
        this._modalsMap.set(modalToken, { defaultParams });
        this._subscribersMap.set(modalToken, []);
        setTimeout(() => {
            this._callbacks.afterRegister.forEach((cb) =>
                cb(new CallbackParams(modalToken))
            );
        }, 0);
    }

    /**
     * Open modal with specific name and close another modals if <close_other> is true
     */
    openModal<T>(
        modalToken: ModalToken<T>,
        closeOther = true,
        params?: T
    ): void {
        if (this._openedModals.getValue().find((m) => m.name === modalToken)) {
            return;
        }

        const modal = this._ensureModalExists(modalToken);

        this._callbacks.beforeOpen.forEach((cb) =>
            cb(new CallbackParams(modalToken))
        );
        if (closeOther) {
            for (const opened_modal of this._openedModals.getValue()) {
                this.closeModal(opened_modal.name);
            }
        }

        this._updateOpenedModals((modals) => [
            ...modals,
            {
                name: modalToken,
                params: params || modal.defaultParams,
                order: modals.length,
            },
        ]);

        this._callSubscribers(modalToken);
        this._callbacks.afterOpen.forEach((cb) =>
            cb(new CallbackParams(modalToken))
        );
    }

    /**
     * Close the modal with specific name
     */
    closeModal<T>(modalToken: ModalToken<T>): void {
        this._ensureModalExists(modalToken);
        this._callbacks.beforeClose.forEach((cb) =>
            cb(new CallbackParams(modalToken))
        );
        this._updateOpenedModals((modals) =>
            modals.filter((m) => m.name !== modalToken)
        );
        this._callSubscribers(modalToken);
        this._callbacks.afterClose.forEach((cb) =>
            cb(new CallbackParams(modalToken))
        );
    }

    /**
     * Check if the modal with the given name is open
     */
    isOpen<T>(modalToken: ModalToken<T>): boolean {
        const open_modal = this._openedModals
            .getValue()
            .find((m) => m.name === modalToken);
        return !!open_modal;
    }

    /**
     * Get the parameters of an opened modal, or null if it is closed
     */
    getParams<T>(modalToken: ModalToken<T>): T | null {
        const openedModal = this._openedModals
            .getValue()
            .find((m) => m.name === modalToken);

        return (openedModal?.params as T) || null;
    }

    /**
     * Get an observable that emits the open/close state of a modal
     */
    selectIsOpen<T>(modalToken: ModalToken<T>): Observable<boolean> {
        this._ensureModalExists(modalToken);
        return this._openedModals.pipe(
            map((om) => {
                return om.find((om) => om.name === modalToken);
            }),
            map((om) => !!om),
            distinctUntilChanged()
        );
    }

    /**
     * Get an observable that emits the parameters of a modal
     */
    selectParams<T>(modalToken: ModalToken<T>): Observable<T | null> {
        this._ensureModalExists(modalToken);
        return this._openedModals.pipe(
            map((om) => {
                return om.find((om) => om.name === modalToken);
            }),
            map((om) => (om?.params as T) || null),
            distinctUntilChanged()
        );
    }

    /**
     * Register a new callback for a specific event
     */
    on(event: Event, cb: CallbackFunction): void {
        if (!ALLOWED_EVENTS.includes(event)) {
            throw new Error(`Unknown event ${event}`);
        }
        this._callbacks[event].push(cb);
    }

    /**
     * Register a subscriber function for a specific modal
     */
    addSubscriber(modalToken: ModalToken, subscriber: () => unknown): void {
        this._manageSubscriber(modalToken, subscriber, "add");
    }

    /**
     * Remove a subscriber function for a specific modal
     * If no subscribers remain, the modal will be deleted
     */
    removeSubscriber<T>(
        modalToken: ModalToken<T>,
        subscriber: () => unknown
    ): void {
        this._manageSubscriber(modalToken, subscriber, "remove");
    }

    /**
     * Get an observable that emits the order of a specific modal
     */
    selectModalOrder<T>(
        modalToken: ModalToken<T>
    ): Observable<OpenedModal<T> | undefined> {
        return this._openedModals.pipe(
            map((om) => {
                return om.find(
                    (om) => om.name === modalToken
                ) as OpenedModal<T>;
            })
        );
    }

    /**
     * Remove modal with specific name
     */
    delModal<T>(modalToken: ModalToken<T>): void {
        this._modalsMap.delete(modalToken);
        this._updateOpenedModals((modals) =>
            modals.filter((m) => m.name !== modalToken)
        );
        this._subscribersMap.delete(modalToken);
    }

    getAllModals() {
        return this._modalsMap;
    }

    getAllSubscribers() {
        return this._subscribersMap;
    }

    /**
     * Ensure that the modal exists and throw an error if not
     */
    private _ensureModalExists<T>(modalToken: ModalToken<T>): Modal<T> {
        const modal = this._getModalByToken(modalToken);
        if (!modal) {
            throw new Error(
                `Modal with token '${modalToken.toString()}' not found`
            );
        }
        return modal;
    }

    /**
     * Call all registered subscribers for a specific modal
     */
    private _callSubscribers<T>(modalToken: ModalToken<T>) {
        const subscribers = this._subscribersMap.get(modalToken);
        if (!subscribers) {
            throw new Error(
                `Subscribers for '${modalToken.toString()} is not defined'`
            );
        }
        for (const subscriber of subscribers) {
            subscriber();
        }
    }

    /**
     * Add or remove a subscriber for a modal
     */
    private _manageSubscriber(
        modalToken: ModalToken<unknown>,
        subscriber: () => unknown,
        action: "add" | "remove"
    ): void {
        const subscribers = this._subscribersMap.get(modalToken) || [];
        const updatedSubscribers =
            action === "add"
                ? [...subscribers, subscriber]
                : subscribers.filter((s) => s !== subscriber);

        if (updatedSubscribers.length > 0) {
            this._subscribersMap.set(modalToken, updatedSubscribers);
        } else {
            this._subscribersMap.delete(modalToken);
            this.delModal(modalToken);
        }
    }

    /**
     * Get a registered modal by its token
     */
    private _getModalByToken<T>(
        modalToken: ModalToken<T>
    ): Modal<T> | undefined {
        return this._modalsMap.get(modalToken) as Modal<T> | undefined;
    }

    /**
     * Update the list of opened modals and notify subscribers
     */
    private _updateOpenedModals(
        updateFn: (modals: OpenedModal<unknown>[]) => OpenedModal<unknown>[]
    ): void {
        this._openedModals.next(updateFn(this._openedModals.getValue()));
    }
}
